.image-mosaic-wrapper {
  position: relative;
  .image-preloader {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    z-index: -10;
  }
}
.image-mosaic {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-auto-rows: 120px;

  opacity: 0.5;
  grid-auto-flow: dense;

  z-index: -1;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #353535;
  font-size: 3rem;
  color: #fff;
  box-shadow: rgba(3, 8, 20, 0.1) 0px 0.15rem 0.5rem,
    rgba(2, 8, 20, 0.1) 0px 0.075rem 0.175rem;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  transition: all 500ms;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;

  transform: scale(0.95);
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 600px) {
  .image-mosaic {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 200px;
  }

  .card-tall {
    grid-row: span 2 / auto;
  }

  .card-wide {
    grid-column: span 2 / auto;
  }
}
