.main-menu {
  position: relative;
  width: 100%;
  background-color: rgba($color: white, $alpha: 0.8);
  border-top: 0.25rem dashed #fff;
  border-bottom: 0.25rem dashed #fff;

  .nav-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
  }

  .item {
    all: unset;
    position: relative;
    font-size: 1.2rem;
    color: #444;
    align-self: center;
    cursor: pointer;
    transition: all 125ms;
    padding: 0.5rem 1rem;
    word-break: keep-all;

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.2) 0%,
        #ffffff 20%,
        #ffffff 80%,
        rgba(255, 255, 255, 0.2) 100%
      );
      box-shadow: 0rem 0rem 50px rgba($color: white, $alpha: 1);
      &.twitch {
        color: #6441a5;
      }
      &.instagram {
        color: #e4405f;
      }
      &.twitter {
        color: #1da1f2;
      }
      &.patreon {
        color: #f96854;
      }
      &.tiktok {
        color: #000;
      }
      &.ko-fi {
        color: #29abe0;
      }
    }
    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: -100%;
      left: -100%;
      width: 200%;
      height: 200%;
      box-shadow: 0rem 0rem 50px rgba($color: black, $alpha: 1);
      //   background: linear-gradient(
      //     90deg,
      //     rgba(255, 255, 255, 0.5) 0%,
      //     #ffffff 20%,
      //     #ffffff 80%,
      //     rgba(255, 255, 255, 0.5) 100%
      //   );
    }

    i {
      margin-right: 5px;
    }
  }
}
